import * as React from "react";
import Layout from "../components/layout";

const AboutPage = () => {
  return (
    <Layout nav="about" pageTitle="About">
      <h1>
        About <small>pyjamacoder.com</small>
      </h1>
      <p>
        Pyjama Coder is a web development blog maintained by me, Donald Chea.
      </p>
      <p>
        From the beginning, I've been interested in learning how everything
        works; from the server to the client and back again. It has led me to
        adopt a wide variety of tools and platforms as well as an open mind.
      </p>
      <p>
        I'm a fan of continuous integration and automation. Although writing
        unit tests can be dull at times, I feel it helps keep you more (in)sane
        in the long run.
      </p>
      <p>
        I am always looking for constant improvement from myself and my peers.
        It is a challenge to constantly change workflows, moreso on a team, but
        it is often required to keep things moving at a rapid pace.
      </p>
      <p>
        My motto is:{" "}
        <q>"If I have to do it more than once, I didn't do it right."</q>
      </p>
      <p>
        It is pretty similar to another thing that I live by:{" "}
        <q>"I don't like to repeat myself."</q>
      </p>
      <p>Let me repeat that last one:</p>
      <p>.</p>
    </Layout>
  );
};

export default AboutPage;
